<template>
  <div class="about">
    <div class="sidenav">
      <div class="login-main-text"></div>
    </div>
    <div class="main">
      <div class="col-10">
        <div class="login-form" v-if="requireNewPassword">
          <div class="mb-5" style="margin-bottom: 90px !important">
            <img src="@/assets/input_logo.svg" style="width: 150px" />
          </div>
          <h2>Ange nytt lösenord</h2>

          <p class="mt-3">
            Ditt lösenord behöver bytas. Vänligen ange ett nytt lösenord för att
            fortsätta.
          </p>
          <p class="mt-3 text-danger" v-if="hasError">
            {{ $t("login.error-login") }}
          </p>
          <div class="form-group mt-5">
            <input
              type="text"
              class="form-control"
              v-model="password.first"
              placeholder="Nytt lösenord"
            />
          </div>
          <button type="submit" class="btn btn-primary" @click="changePassword">
            Fortsätt
          </button>
        </div>
        <div class="login-form" v-if="!requireNewPassword">
          <div class="mb-5" style="margin-bottom: 90px !important">
            <img src="@/assets/input_logo.svg" style="width: 150px" />
          </div>
          <h2
            :class="{
              'do-wait-auth': isAuthenticating,
              'wait-auth': !isAuthenticating
            }"
          >
            <i class="fas fa-spinner fa-spin text-muted mr-3"></i>
            {{ $t("login.wait") }} ..
          </h2>
          <div
            style="position: relative; top: 0"
            :class="{ 'is-auth': isAuthenticating }"
          >
            <h2>{{ $t("login.title") }}</h2>

            <p class="mt-3 text-danger" v-if="hasError">
              {{ $t("login.error-login") }}
            </p>
            <div class="form-group mt-4">
              <input
                type="text"
                class="form-control"
                v-model="auth.username"
                :placeholder="$t('login.username')"
              />
            </div>
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                v-model="auth.password"
                :placeholder="$t('login.password')"
              />
            </div>
            <button
              type="submit"
              class="btn btn-primary"
              @click="runAuthentication"
            >
              {{ $t("login.login") }}
            </button>
            <div class="mt-5 text-muted font-xs">
              {{ $t("login.helptext") }}
              <a href="mailto:supportb2b@inputinterior.se"
                >{{ $t("login.helptext_two") }}.</a
              >
            </div>
            <div class="pt-5">
              <locale-switcher></locale-switcher>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputAPI from "@/services/api/input";
import localeSwitcher from "@/components/layout/localeSwitcher";
export default {
  name: "Login",
  components: { localeSwitcher },
  computed: {
    requireNewPassword() {
      return this.$store.getters.requireNewPassword;
    }
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
    },

    changePassword() {
      InputAPI.changePassword(this.auth.password, this.password.first).then(
        response => {
          this.$store.dispatch("completeAuth");
          this.isAuthenticating = false;
          this.$router.push("/");
        }
      );
    },
    runAuthentication() {
      this.isAuthenticating = true;
      this.hasError = false;
      this.$store.dispatch("doAuthentication", this.auth).then(
        response => {
          Promise.all([
            this.$store.dispatch("getShops"),
            this.$store.dispatch("getCarts"),
            this.$store.dispatch("getProducts"),
            this.$store.dispatch("getCustomers"),
            this.$store.dispatch("getUser")
          ]).then(() => {
            if (!this.requireNewPassword) {
              this.$store.dispatch("completeAuth");
              this.isAuthenticating = false;
              this.$router.push("/");
            }
          });
        },
        error => {
          this.isAuthenticating = false;
          this.hasError = true;
          console.error("Authentication failed");
        }
      );
    }
  },

  data() {
    return {
      isAuthenticating: false,
      hasError: false,
      setNewPassword: false,
      locales: ["sv", "da"],
      password: {
        first: "",
        second: ""
      },
      auth: {
        username: "",
        password: "",
        punchout: false
      }
    };
  }
};
</script>
