var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_vm._m(0),_c('div',{staticClass:"main"},[_c('div',{staticClass:"col-10"},[(_vm.requireNewPassword)?_c('div',{staticClass:"login-form"},[_vm._m(1),_c('h2',[_vm._v("Ange nytt lösenord")]),_c('p',{staticClass:"mt-3"},[_vm._v(" Ditt lösenord behöver bytas. Vänligen ange ett nytt lösenord för att fortsätta. ")]),(_vm.hasError)?_c('p',{staticClass:"mt-3 text-danger"},[_vm._v(" "+_vm._s(_vm.$t("login.error-login"))+" ")]):_vm._e(),_c('div',{staticClass:"form-group mt-5"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password.first),expression:"password.first"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Nytt lösenord"},domProps:{"value":(_vm.password.first)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.password, "first", $event.target.value)}}})]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"},on:{"click":_vm.changePassword}},[_vm._v(" Fortsätt ")])]):_vm._e(),(!_vm.requireNewPassword)?_c('div',{staticClass:"login-form"},[_vm._m(2),_c('h2',{class:{
            'do-wait-auth': _vm.isAuthenticating,
            'wait-auth': !_vm.isAuthenticating
          }},[_c('i',{staticClass:"fas fa-spinner fa-spin text-muted mr-3"}),_vm._v(" "+_vm._s(_vm.$t("login.wait"))+" .. ")]),_c('div',{class:{ 'is-auth': _vm.isAuthenticating },staticStyle:{"position":"relative","top":"0"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("login.title")))]),(_vm.hasError)?_c('p',{staticClass:"mt-3 text-danger"},[_vm._v(" "+_vm._s(_vm.$t("login.error-login"))+" ")]):_vm._e(),_c('div',{staticClass:"form-group mt-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.auth.username),expression:"auth.username"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.$t('login.username')},domProps:{"value":(_vm.auth.username)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.auth, "username", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.auth.password),expression:"auth.password"}],staticClass:"form-control",attrs:{"type":"password","placeholder":_vm.$t('login.password')},domProps:{"value":(_vm.auth.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.auth, "password", $event.target.value)}}})]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"},on:{"click":_vm.runAuthentication}},[_vm._v(" "+_vm._s(_vm.$t("login.login"))+" ")]),_c('div',{staticClass:"mt-5 text-muted font-xs"},[_vm._v(" "+_vm._s(_vm.$t("login.helptext"))+" "),_c('a',{attrs:{"href":"mailto:supportb2b@inputinterior.se"}},[_vm._v(_vm._s(_vm.$t("login.helptext_two"))+".")])]),_c('div',{staticClass:"pt-5"},[_c('locale-switcher')],1)])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidenav"},[_c('div',{staticClass:"login-main-text"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-5",staticStyle:{"margin-bottom":"90px !important"}},[_c('img',{staticStyle:{"width":"150px"},attrs:{"src":require("@/assets/input_logo.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-5",staticStyle:{"margin-bottom":"90px !important"}},[_c('img',{staticStyle:{"width":"150px"},attrs:{"src":require("@/assets/input_logo.svg")}})])
}]

export { render, staticRenderFns }