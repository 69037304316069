<template>
  <div class="container-fluid cart">
    <div class="row subbar">
      <div class="container-fluid">
        <div class="side-push">
          <div class="row">
            <div class="col">
              <nav>
                <span
                  ><a href="/">{{ $t("menu.home") }}</a></span
                >
                <span
                  >/<a href="/">{{ $t("menu.orders") }}</a></span
                >
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="side-push">
      <div class="row title-wrapper">
        <div class="col-6">
          <h1 class="mt-0 mb-0">{{ $t("menu.orders") }}</h1>
        </div>
        <div class="col-6 text-right">
          <button class="btn btn-dark" @click="downloadCSVData">
            {{ $t("orders.export-orders") }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="cart-table" cellspacing="0">
            <thead>
              <tr>
                <th class="order-number">{{ $t("orders.order-number") }}</th>
                <th class="order-date">{{ $t("orders.date") }}</th>
                <th class="order-status">Status</th>
                <th class="order-title">{{ $t("orders.project") }}</th>
                <th class="order-contact">{{ $t("cart.contact") }}</th>
                <th class="order-total">{{ $t("orders.total") }}</th>
                <th class="">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="orders.length === 0">
                <tr>
                  <td colspan="7" class="text-center pt-5">
                    <h5 class="text-muted">{{ $t("orders.orders-empty") }}</h5>
                  </td>
                </tr>
              </template>
              <template v-if="orders.length !== 0">
                <tr
                  class="woocommerce-cart-form__cart-item cart_item"
                  v-for="order in orders"
                  :key="order.id"
                >
                  <td>{{ order.ordernr }}</td>
                  <td>{{ order.date.substr(0, 10) }}</td>
                  <td>{{ order.status_text }}</td>
                  <td>{{ order.projekt }}</td>
                  <td>{{ order.contact }}</td>
                  <td>{{ getSum(order) }}</td>
                  <td class="text-right">
                    <router-link
                      tag="button"
                      class="btn btn-outline-dark btn-sm"
                      :to="{ name: 'orders.view', params: { id: order.id } }"
                      >Visa
                    </router-link>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  methods: {
    getSum(order) {
      let sum = order.lines.reduce(
        (total, arg) => parseFloat(total) + parseFloat(arg.pris) * arg.antal,
        0
      );
      return this.formatPrice(sum);
    },

    getQtyOrder(order) {
      return order.lines.reduce(
        (total, arg) => parseFloat(total) + parseFloat(arg.antal),
        0
      );
    },
    downloadCSVData() {
      let csv =
        this.$t("orders.order-number") +
        ";" +
        this.$t("orders.date") +
        ";Status;" +
        this.$t("orders.project") +
        ";" +
        this.$t("cart.contact") +
        ";" +
        this.$t("menu.products") +
        ";" +
        this.$t("orders.total") +
        ";" +
        this.$t("cart.currency") +
        "\n";
      this.orders.forEach((row) => {
        csv += row.ordernr + ";";
        csv += row.date + ";";
        csv += row.status_text + ";";
        csv += row.projekt + ";";
        csv += row.contact + ";";
        csv += this.getQtyOrder(row) + ";";
        csv += this.getSum(row) + ";";
        csv += row.currency;
        csv += "\n";
      });

      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = "orders_input_b2b.csv";
      anchor.click();
    },
    formatPrice(price) {
      let currency = this.$store.getters.getCurrentShop.info.valuta;
      return this.$formatPrice(price, currency);
    },
  },
  computed: {
    orders() {
      return this.$store.getters.getCompletedCarts;
    },
  },
  mounted() {
    this.$store.dispatch("getCarts");
  },
  data() {
    return {};
  },
};
</script>
