<template>
  <div class="container-fluid cart">
    <div class="row subbar">
      <div class="container-fluid">
        <div class="side-push">
          <div class="row">
            <div class="col">
              <nav>
                <span
                  ><a href="/">{{ $t("menu.home") }}</a></span
                >
                <span
                  >/<a href="/users">{{ $t("menu.users") }}</a></span
                >
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="side-push">
      <div class="row">
        <div class="col-8"></div>
        <div class="col-4 pt-4">
          <h6 class="mb-3">{{ $t("users.new-user") }}</h6>

          <div class="form-group">
            <input
              type="text"
              class="form-control"
              v-model="user.username"
              :placeholder="$t('users.new-user')"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              v-model="user.password"
              :placeholder="$t('users.password')"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              v-model="user.name"
              :placeholder="$t('users.name')"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              v-model="user.email"
              :placeholder="$t('users.email')"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              v-model="user.shop_id"
              :placeholder="$t('users.shop')"
            />
          </div>
          <div class="form-group">
            <select class="form-control" v-model="user.level">
              <option v-for="level in levels" :key="level.id" :value="level">
                {{ level.label }}
              </option>
            </select>
          </div>

          <button @click="createUser" class="btn btn-primary">
            {{ $t("users.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputAPI from "@/services/api/input";
export default {
  props: {},
  methods: {
    createUser() {
      InputAPI.createUser(
        this.user.username,
        this.user.password,
        this.user.name,
        this.user.email,
        this.user.shop_id,
        this.user.level
      ).then((response) => {
        this.$toast.success("Sparad", {
          timeout: 3000,
        });
      });
    },
  },
  mounted() {},
  data() {
    return {
      levels: [
        { id: 0, label: this.$t("users.level-0") },
        { id: 1, label: this.$t("users.level-1") },
        { id: 3, label: this.$t("users.level-3") },
        { id: 7, label: this.$t("users.level-7") },
      ],
      user: {
        username: "",
        password: "",
        name: "",
        email: "",
        shop_id: "",
        level: { id: 0, label: this.$t("users.level-0") },
      },
    };
  },
};
</script>
