<template>
  <div></div>
</template>
<script>
export default {
  name: "LoginExt",
  methods: {
    runAuthentication() {
      this.$store.dispatch("doAuthentication", this.auth).then(
        (response) => {
          Promise.all([
            this.$store.dispatch("getShops"),
            this.$store.dispatch("getCarts"),
            this.$store.dispatch("getProducts"),
            this.$store.dispatch("getCustomers"),
          ]).then(() => {
            this.$store.dispatch("completeAuth");
            this.isAuthenticating = false;
            this.$router.push("/");
          });
        },
        (error) => {
          console.log(error);
          console.error("Authentication failed");
        }
      );
    },
  },

  mounted() {
    let q = this.$route.query;
    this.$store.dispatch("setPunchout", q);
    this.auth.username = q.username;
    this.auth.password = q.password;
    this.runAuthentication();
  },

  data() {
    return {
      auth: {
        username: "",
        password: "",
        punchout: true,
      },
    };
  },
};
</script>
