<template>
  <div class="container-fluid content-page" shop="26" line="10">
    <div class="row subbar">
      <div class="container-fluid">
        <div class="side-push">
          <div class="row">
            <div class="col">
              <nav>
                <span
                  ><a href="/">{{ $t("menu.home") }}</a></span
                ><span
                  >/<a href="/information">{{
                    $t("menu.information")
                  }}</a></span
                >
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="side-push">
      <div class="row">
        <div
          class="col-12 col-md-6 pt-5"
          style="min-height: 60vh;"
          v-html="getInformation"
        ></div>
        <div class="col-md-6 pt-5 ">
          <img src="https://www.inputinterior.se/wp-content/uploads/2018/01/input-19-1024x680.jpg" class="img-fluid" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  computed: {
    getInformation() {
      if (
        this.$store.getters.getCurrentShop.info.information &&
        this.$store.getters.getCurrentShop.info.information.length !== 0
      ) {
        let currentLanguage = this.$i18n.locale;
        if (currentLanguage === "sv") {
          currentLanguage = "SE";
        }
        if (currentLanguage === "no") {
          currentLanguage = "NO";
        }
        if (currentLanguage === "en") {
          currentLanguage = "EN";
        }
        if (currentLanguage === "da") {
          currentLanguage = "DK";
        }

        return this.$store.getters.getCurrentShop.info.information.find(
          (item) => item.lang === currentLanguage
        ).instr;
      } else {
        return this.$store.getters.getCurrentShop.info.information[0];
      }
    },
  },
};
</script>
